import React from "react"

// Libraries
import styled from "styled-components"

// Components
import DownloadsSection from "components/downloads-sections"
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
`

const BillingSection = props => {
	return (
		<DownloadsSection
			id="sti-order-forms"
			viewAllowed={props.viewAllowed}
			data-filter="sexual-health" data-filter-type="devices" data-section="orderforms"
		>
			<StyledContainer>
				<div className="block__content">
					<h3>Order Forms</h3>
				</div>
				<div className="block">
					<div className="block__material">
					<ul className="downloadsList">
							<li>
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSckV7H-0Jmv4pneCvLMYpZoenkRyKy01RI7ZxMrxdoP4j8OGg/viewform?usp=share_link" target="_blank" rel="noopener noreferrer">Complimentary supporting material</a>
							</li>
							<li>
								<a href="https://docs.google.com/forms/d/1mFI5SuKRhP-2KrghSHCBR_5g9qxRglq02tsMiuJ3v6Q/edit?ts=64641274" target="_blank" rel="noopener noreferrer">Re-ordering product</a>
							</li>
					</ul>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default BillingSection
